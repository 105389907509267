<template>
  <div class="transport-timeline">
    <div class="nav">
      <p>Transport state: {{ transportState }}</p>
      <button @click="toggleTransport">
        {{ transportState === 'started' ? 'Stop' : 'Start' }}
      </button>
    </div>

    <div class="monospace">
      <div class="nav">
        <div>
          <div>
            BPM:
          </div>
          <input type="number" min="20" max="600" v-model="bpm" />
          <input type="range" min="20" max="600" v-model="bpm" class="slider" />
        </div>
      </div>
      <!-- <p>
        transportProgress: {{ transportProgress.toFixed(2) }}
      </p> -->
      <p>transportPosition: {{ transportPosition }}</p>
      <p>
        transportSeconds: {{ transportSeconds.toFixed(2) }}
      </p>
      <!-- <p>
        transportTicks: {{ transportTicks }}
      </p>-->
    </div>

    <div class="timeline">
      <div
        class="progress"
        :style="{
          transform: `translateX(${transportProgress * 100}%)`,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransportTimeline',
  props: {
  },

  data() {
    return {
      bpm: 100,
      transportState: null,
      // progressTimer: null,
      transportProgress: 0,
      transportPosition: '0:0:0',
      transportSeconds: 0,
      transportTicks: null,
    };
  },

  methods: {
    followProgress() {
      // keep the playhead on track
      // console.log('this.$Tone.Transport.progress', this.$Tone.Transport.progress);
      this.transportProgress = this.$Tone.Transport.progress;
      this.transportPosition = this.$Tone.Transport.position;
      this.transportSeconds = this.$Tone.Transport.seconds;
      this.transportTicks = this.$Tone.Transport.ticks;

      if (this.transportState === 'started') {
        window.requestAnimationFrame(this.followProgress);
      }
    },

    toggleTransport() {
      if (this.transportState === 'started') {
        this.$Tone.Transport.stop();
      } else {
        this.$Tone.Transport.start();
      }
      this.refreshTransportState();
    },

    refreshTransportState() {
      this.transportState = this.$Tone.Transport.state;
    },

    setTransport() {
      const { Transport } = this.$Tone;
      // set the transport
      Transport.bpm.value = this.bpm;
      Transport.loop = true;
      Transport.loopStart = '0m';
      Transport.loopEnd = '4m';
    },
  },

  watch: {
    transportState(to) {
      console.log('Transport State', to);
      if (to === 'started') {
        window.requestAnimationFrame(this.followProgress);
      }
    },

    bpm(to) {
      const { Transport } = this.$Tone;
      Transport.bpm.value = to;
    },
  },

  mounted() {
    /*
    PPQ: 192
    blockTime: 0.0029024943310657597
    disposed: false
    loop: false
    loopEnd: 0
    loopStart: 0
    position: "0:0:0"
    progress: 0
    sampleTime: 0.000022675736961451248
    seconds: 0
    state: "stopped"
    swing: 0
    swingSubdivision: "8n"
    ticks: 0
    timeSignature: 4
    */

    this.setTransport();
    this.refreshTransportState();
  },

  beforeDestroy() {
    this.$Tone.Transport.stop();
    this.refreshTransportState();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.timeline {
  width: 100%;
  height: 100px;
  background: #eee;
  position: relative;
  overflow: hidden;
}
.progress {
  width: 100%;
  height: 100%;
  background: transparent;
  border-left: 2px solid rgba(255, 0, 0, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  // box-shadow: -5px 0px 15px rgba(255,0,0,0.4);
  // transition: transform .09s;
}

// .slider {
//   width: 100%;
//   max-width: 350px;
// }

.monospace {
  font-family: ui-monospace, monospace;
}
</style>
