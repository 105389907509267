<template>
  <div class="about">
    <!-- <h1>Transport</h1> -->

    <h2>Tone.js Transport Timeline test</h2>
    <TransportTimeline />

  </div>
</template>

<script>
// @ is an alias to /src
import TransportTimeline from '@/components/TransportTimeline.vue';

export default {
  name: 'TransportToneJs',
  components: {
    TransportTimeline,
  },

  data() {
    return {
    };
  },

  methods: {
  },
};
</script>
